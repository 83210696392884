<template>
  <div class="he-ui-location-overview">
    <phase
      v-for="phase in phases"
      class="align-items-end"
      :key="phase.id"
      :data="phase"
      :showTimeCircle="false"
      :showButton="false" />
    <phase-report
      v-for="item in reports"
      :key="item.id"
      :data="item" />
  </div>
</template>

<script>
import Phase from '@/components/Phase'
import PhaseReport from '@/components/statistics/PhaseReport'

export default {
  components: {
    Phase,
    PhaseReport
  },
  data () {
    return {
      reports: [
        [
          { icon: 'schedule', text: this.$t('main.scanning_time'), data: '1h 15min' },
          { icon: 'place', text: this.$t('main.place'), data: 'Sala de curas' },
          { icon: 'account_circle', text: this.$t('main.id_staff'), data: '33uff4-ff333fkFF' }
        ],
        [
          { icon: 'schedule', text: this.$t('main.scanning_time'), data: '1h 15min' },
          { icon: 'place', text: this.$t('main.place'), data: 'Sala de curas' },
          { icon: 'account_circle', text: this.$t('main.id_staff'), data: '33uff4-ff333fkFF' }
        ],
        [
          { icon: 'schedule', text: this.$t('main.scanning_time'), data: '1h 15min' },
          { icon: 'place', text: this.$t('main.place'), data: 'Sala de curas' },
          { icon: 'account_circle', text: this.$t('main.id_staff'), data: '33uff4-ff333fkFF' }
        ]
      ],
      phases: [
        {
          id: 1,
          src: require('../../assets/statistics/admision.svg'),
          alt: 'admission',
          tag: this.$t('main.admission'),
          active: true
        },
        {
          id: 2,
          src: require('../../assets/statistics/quirofano.svg'),
          alt: 'operating_room',
          tag: this.$t('main.operating_room'),
          active: false
        },
        {
          id: 3,
          src: require('../../assets/statistics/postoperatorio.svg'),
          alt: 'posoperative',
          tag: this.$t('main.postoperative'),
          active: false
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-location-overview {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10%;
}
</style>
