<template>
  <div class="he-ui-phase-report">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="he-ui-phase-report-wrapper mb-2">
      <span
        class="he-ui-phase-report-icon material-icons-outlined">{{ item.icon }}</span>
      <div class="he-ui-phase-report-text">
        {{ item.text }}
      </div>
      <div class="he-ui-phase-report-data">
        {{ item.data }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.he-ui-phase-report-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  gap: 0;
}
.he-ui-phase-report-icon {
  color: white;
  text-align: left;
}
.he-ui-phase-report-text {
  color: white;
  text-transform: uppercase;
  font-size: .6rem;
  font-weight: 600;
  text-align: left;
  line-height: 2.5;
}
.he-ui-phase-report-data {
  background: white;
  border-radius: 10px;
  font-size: .7rem;
  font-weight: 600;
  line-height: 2;
}
</style>
